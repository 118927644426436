<!-- Código html -->
<template>
    
    <!-- Contenedor principal -->
    <div id="contenedor_principal">

           <div class="titulo">
                API Pogen Data
            </div>

            <div class="parrafo">
                Esta herramienta es utilizada principalmente para obtener 
                información correspondiente al usuario como pueden ser los
                datos de sus plazas, por fecha y hora, obtener un listado 
                de sus plazas con los accesos pertenecientes. Todas las 
                respuestas serán devueltas en JSON
            </div>

            <div class="parrafo">
                Todas las operaciones de consulta requieren de un token de
                sesión, por lo que el primer paso es conseguir dicho token.
            </div>

            <div class="titulo1 mt2em">
                Obtención del token
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li><strong>email</strong>: Correo usuario</li>
                    <li><strong>password</strong>: Contraseña de usuario</li>
                </ul>
                <div class="categoria">
                    Detalles de la petición:
                </div>                
                <ul>
                    <li><strong>url</strong>: https://app.pogendata.com/api/login</li>
                    <li><strong>Método</strong>: post</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>Objeto tipo json, contiene dos propiedades, success
                        que contiene 0 para negativo y 1 para credenciales 
                        correctas, además del api_key que es el token a usar.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="javascript">

    /* Función para hacer login */
    async function obtenerToken(email,password){

        /* Url para obtener el token */
        let url = 'https://app.pogendata.com/api/login';

        /* Se crea un objeto del tipo FormData para enviarlo en la petición */
        let fd = new FormData();

        /* Se le agrega los datos email y password al objeto FormData */
        fd.append("email",email);
        fd.append("password",password);

        /* Hacer la petición */
        fetch(url,{
            body:fd,
            method:'post'
        }).then(respuesta=>{

            /* Convertir la respuesta en un objeto json entendible */
            respuesta.json()
            .then(json=>{

                /* El atributo api_key nos dará el token */
                console.log(json.api_key);

            });
        })
        .catch(error=>console.log(error));
    }

                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">

    /* Función para obtener el token */                        
    function obtenerToken($email,$password){

        $url = 'https://app.pogendata.com/api/login';

        /* Se crea un arreglo con los parámetros a enviar */
        $arregloDatos = array(
            'email'=>$email,
            'password'=>$password
        );

        /* Se convierte el arreglo datos en objeto de petición */
        $datos = http_build_query($arregloDatos);

        /* Se crea un arreglo con las opciones de la conexion */
        $opciones = array(
            'http'=>array(
                'method'=>'POST',
                'header'=>'Content-type: application/x-www-form-urlencoded',
                'content'=>$datos
            )
        );

        /* Se converte arreglo datos en un objeto con las opciones de la petición */
        $contexto = stream_context_create($opciones);

        /* Se realiza la petición con los parámetros $url, false y el objeto contexto */
        $respuesta = file_get_contents($url,false,$contexto);

        /* Como la respuesta es un json se convierte a un arreglo para hacerlo entendible  */
        $json = json_decode($respuesta,flags:JSON_OBJECT_AS_ARRAY);

        /* El token se obtiene en el atributo api_key */
        return $json['api_key'];
    }
                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class="vb">

    /* Clase modelo Token */
    public class Token
    {
        public String Api_key { get; set; }
    }

    /* Metodo para obtener el token */
    public static async Task&lt;String&gt;  Acceso(String usuario, String contrasenia)
    {

        /* Mediante la clase HttpUtility se crea una objeto para los parámetros*/
        var query = HttpUtility.ParseQueryString(string.Empty);

        /* Se agregan usuario y contraseña al objeto */
        query["email"] = usuario;
        query["password"] = contrasenia;

        /* Se convierte el objeto en cadena */
        string queryString = query.ToString();

        /* Se crea el objeto con la dirección y se le agrega la cadena con los parámetros */
        String direccion = "https://app.pogendata.com/api/login?" + queryString;

        /* Se crea el cliente http para la consulta */
        using (var clienteHttp = new HttpClient())
        {

            /* Realizar la petición */
            var respuesta = await clienteHttp.PostAsync(direccion, null);

            /* Extraer en forma de cadena la respuesta */
            var cadenaRespuesta = await respuesta.Content.ReadAsStringAsync();

            /* Convertir la respuesta en un objeto de la clase Token */
            var token = JsonConvert.DeserializeObject&lt;Token&gt;(cadenaRespuesta);

            /* Imprimir token */
            return token.Api_key;

        }

    }

                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">

    # Importar librerias adicionales
    import requests

    # Metódo para obtener el token
    def obtenerToken(usuario, contrasenia):

        # Definir la url de la petición
        url = "https://app.pogendata.com/api/login"

        # Definir los parámetros de la petición
        parametros = {
            'email' : usuario,
            'password' : contrasenia
        }

        # Realizar petición y asignarla a una respuesta
        respuesta = requests.post( url, params=parametros)

        # Convertir respuesta en json
        json = respuesta.json()

        # Retornar el token
        return json['api_key']

                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Lista de plazas del usuario
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li>
                        <strong>Token</strong>, se debe agregar en la cabecera de la petición
                        como Authorization tipo bearer
                    </li>
                </ul>
                <div class="categoria">
                    Detalles de la petición:
                </div>                
                <ul>
                    <li><strong>url</strong>: https://app.pogendata.com/api/data/plazas</li>
                    <li><strong>Método</strong>: post</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>
                        Objeto tipo jason, un arreglo con la lista de plazas 
                        vinculadas al usuario.
                    </li>
                </ul>                                
            </div>            

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="js">

    /* Función para obtener las plazas asociadas al usuario */
    async function obtenerPlazas(token){

        /* Url para obtener las plazas */
        let url = 'https://app.pogendata.com/api/data/plazas';

        /* Realizar petición, el método debe ser post y en los encabezados debe ir
        un atributo llamado authorization con el valor bearer seguido del token */
        fetch(url,{
        method:'post',
        headers:{
            Authorization:`bearer ${token}`
        }
        }).then(respuesta=>{
        
        /* Como la respuesta se recibe en formato json se realiza la conversión */
        respuesta.json()
        .then(json=>{

            /* Las plazas vienen en un arreglo con las diferentes plazas */
            console.log(json);

        });
        }).catch(error=>console.log(error));

    }                        

                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">

    /* Función para obtener las plazas relacionadas al usuario */
    function obtenerPlazas($token){

        /* Url de consulta */
        $url = 'https://app.pogendata.com/api/data/plazas';

        /* Arreglo con opciones de la conexión */
        $opciones = array(
            'http'=>array(
                'method'=>'POST',
                'header'=>'Authorization: Bearer ' . $token
            )
        );

        /* Crear contexto para petición */
        $contexto = stream_context_create($opciones);

        /* Realizar consulta */
        $respuesta = file_get_contents($url,false,$contexto);

        /* Convertir respuesta en arreglo */
        $json = json_decode($respuesta);

        /* Retornar datos */
        return $json;

    }
                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class=".NET">

    /* Clase modelo para establecimiento */
    public class Establecimiento
    {
        public int Id { get; set; }
        public String Name { get; set; }
        public EstablecimientoAcceso Accesos { get; set; }
    }

    /* Clase modelo para el acceso principal en el establecimiento */ 
    public class EstablecimientoAcceso
    {
        public int Id { get; set; }

        public String Name { get; set; }

        public String Color { get; set; }
    }

    /* Método para obtener la lista de establecimientos */
    public static async Task&lt;List&lt;Establecimiento&gt;&gt; Establecimientos(String token)
    {

        /* Dirección de la consulta */
        String direccion = "https://app.pogendata.com/api/data/plazas";

        /* Crear el cliente http */
        using (var clienteHttp = new HttpClient())
        {

            /* Agregar el token en la cabecera */
            clienteHttp.DefaultRequestHeaders.Add("Authorization",$"Bearer {token}");

            /* Realizar petición */
            var respuesta = await clienteHttp.PostAsync(direccion, null);

            /* Obtener la respuesta en forma de cadena */
            var cadenaRespuesta = await respuesta.Content.ReadAsStringAsync();

            /* Convertir en objeto json la cadena de respuesta */
            //var json = JsonConvert.DeserializeObject(cadenaRespuesta);

            var json = JsonConvert.DeserializeObject&lt;List&lt;Establecimiento&gt;&gt;(cadenaRespuesta);

            /* Imprimir resultado */
            return json;
        }
    }

                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">

    # Importar librerías adicionales
    import requests

    # Método para obtener la lista de establecimientos del usuario
    def obtenerEstablecimientos(token):

        # Definir url
        url = 'https://app.pogendata.com/api/data/plazas'

        # Establecer encabezado de la petición
        encabezado = {
            'Authorization' : 'Bearer ' + token
        }

        # Realizar petición y asignar respuesta
        respuesta = requests.post(url, headers=encabezado)

        # Convertir respuesta en json
        json = respuesta.json()

        # Retornar establecimientos
        return json

                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Lista de accesos por plaza
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li><strong>plaza_id</strong>: valor entero con el id de la plaza</li>
                    <li>
                        <strong>Token</strong>, se debe agregar en la cabecera de la petición
                        como Authorization tipo bearer
                    </li>                    
                </ul>
                <div class="categoria">
                    Detalles de la petición:
                </div>                
                <ul>
                    <li><strong>url</strong>: https://app.pogendata.com/api/data/accesos</li>
                    <li><strong>Método</strong>: post</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>Objeto tipo json, contiene el arreglo con la lista
                        de accesos y sus detalles.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs v-if="pa === 0">
                        <code class="js">

    /* Función para obtener los accesos de una plaza */
    async function obtenerAccesos(token,plaza_id){

        /* Url para obtener los accesos */
        let url = 'https://app.pogendata.com/api/data/accesos';

        /* Crear el objeto formdata */
        let fd = new FormData();

        /* Agregar al objeto formData el parámetro plaza_id */
        fd.append('plaza_id',plaza_id)

        /* Realizar petición, el método debe ser post y en los encabezados debe ir
        un atributo llamado authorization con el valor bearer seguido del token */
        fetch(url,{
        method:'post',
        headers:{
            Authorization:`bearer ${token}`
        },
        body:fd
        }).then(respuesta=>{
        
        /* Como la respuesta se recibe en formato json se realiza la conversión */
        respuesta.json()
        .then(json=>{

            /* Los accesos vienen en un arreglo. */
            console.log(json);

        });
        }).catch(error=>console.log(error));

    }
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs v-if="pa === 1">
                        <code class="PHP">
                    
    /* Función para obtener los accesos de una plaza en específico */
    function obtenerAccesos($token,$plaza_id){

        /* Url de consulta */
        $url = 'https://app.pogendata.com/api/data/accesos';

        /* Se crea un arreglo con los parámetros a enviar */
        $arregloDatos = array(
            'plaza_id'=>$plaza_id
        );

        /* Se convierte el arreglo datos en objeto de petición */
        $datos = http_build_query($arregloDatos);        

        /* Arreglo con opciones de la conexión */
        $opciones = array(
            'http'=>array(
                'method'=>'POST',
                'header'=>array(
                    'Authorization: Bearer ' . $token,
                    'Content-type: application/x-www-for-urlencoded'
                ),
                'content'=>$datos
            )
        );

        /* Crear contexto para petición */
        $contexto = stream_context_create($opciones);

        /* Realizar consulta */
        $respuesta = file_get_contents($url,false,$contexto);

        /* Convertir respuesta en arreglo */
        $json = json_decode($respuesta);

        /* Retornar datos */
        return $json;

    }

                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs v-if="pa === 2">
                        <code class="vb">

    /* Clase modelo para el acceso */
    public class ClaseAcceso
    {
        public int Id { get; set; }
        public String Nombre { get; set; }
        public String Color { get; set; }
    }

    /* Método para obtener la lista de accesos por plaza */
    public static async Task&lt;List&lt;ClaseAcceso&gt;&gt; Accesos(String token, int plaza_id)
    {

        /* Dirección de la consulta */
        String direccion = "https://app.pogendata.com/api/data/accesos";

        /* Crear objeto para manejar los parámetros */
        var objetoParametros = HttpUtility.ParseQueryString(String.Empty);

        /* Agregar parámetros al objetoParametros */
        objetoParametros["plaza_id"] = $"{plaza_id}";

        /* Convertir en cadena objetoParametros */
        var cadenaParametros = objetoParametros.ToString();

        /* Agregar a la dirección la cadena con los parámetros */
        direccion += "?" + cadenaParametros;

        /* Crear el objeto httpCliente */

        using (var clienteHttp = new HttpClient())
        {

            /* Agregar cabecera con el token */
            clienteHttp.DefaultRequestHeaders.Add("Authorization", $"Bearer {token}");

            /* Realizar la petición */
            var respuesta = await clienteHttp.PostAsync(direccion, null);

            /* Convertir la respuesta en una cadena entendible */
            var cadenaRespuesta = await respuesta.Content.ReadAsStringAsync();

            /* Convertir el objeto en json */
            var json = JsonConvert.DeserializeObject&lt;List&lt;ClaseAcceso&gt;&gt;(cadenaRespuesta);

            /* Imprimir resultado */
            return json;

        }

    }

                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs v-if="pa === 3">
                        <code class="Python">

    # Importar librerias adicionales
    import requests

    # Método para obtener los accesos
    def obtenerAccesos(token,plaza_id):

        # Definir la url a utilizar
        url = 'https://app.pogendata.com/api/data/accesos'

        # Establecer encabezado de la petición
        encabezado = {
            'Authorization' : 'Bearer ' + token
        }

        # Establecer parámetros de la petición
        parametros = {
            'plaza_id' : plaza_id
        }

        # Realizar petición y asignar respuesta
        respuesta = requests.post(url, params=parametros, headers=encabezado)

        # Convertir respuesta en json
        json = respuesta.json()

        # Retornar accesos
        return json

                        </code>
                    </pre>                    
                </div>
            </div>
            
            <div class="titulo1">
                Informacion de entradas
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li>
                        <strong>Token</strong>, se debe agregar en la cabecera de la petición
                        como Authorization tipo bearer
                    </li>                    
                    <li>
                       <strong>plaza_id</strong>: el id de la plaza a consultar, si se omite 
                        se obtienen las entradas de todas las plazas, el tipo
                        de dato es entero
                    </li>
                    <li>
                        <strong>inicio</strong>: fecha y hora de inicio del periodo que se
                        quiere consultar, formato 'YYYY-MM-DD HH:mm:ss'
                    </li>
                    <li>
                        <strong>fin</strong>: fecha y hora de fin del periodo que se
                        quiere consultar, formato 'YYYY-MM-DD HH:mm:ss'
                    </li>
                    <li>
                        <strong>tipo</strong>: tipo de segmentación de reporte, puede ser days
                        hours,weeks,months,years
                    </li>
                    <li>
                        <strong>pagina</strong>: si la cantidad de datos requiere paginación
                        el valor del parámetro determinara en que pagina 
                        comienza el resultado, el valor defecto es 0
                    </li>
                    <li>
                        <strong>agrupar</strong>: tipo booleano que determina si en el resultado
                        deben estar agrupados los accesos, defecto false
                    </li>
                    <li>
                        <strong>campo_orden</strong>: posibles valores fecha ó inserción, define
                        cual es el orden que seguira la información
                    </li>
                    <li>
                        <strong>orden</strong>: puede ser asc o desc, determina el orden en el 
                        se recibirán los datos
                    </li>
                </ul>
                <div class="categoria">
                    Detalles de la petición:
                </div>                
                <ul>
                    <li> <strong>url</strong>: https://app.pogendata.com/api/data/datos</li>
                    <li> <strong>método</strong>: post</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>Objeto tipo json, los datos de entradas en el atributo
                        "data".
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="js">

    /* Función para obtener las entradas */
    async function obtenerEntradas(token,plaza_id,inicio,fin,tipo,pagina,agrupar,campo_orden,orden){

        /* Url para obtener las entradas */
        let url = 'https://app.pogendata.com/api/data/datos';

        /* Crear el objeto formdata */
        let fd = new FormData();

        /* Agregar al objeto formData los parámetros necesarios */
        fd.append('plaza_id',plaza_id);
        fd.append('inicio',inicio);
        fd.append('fin',fin);
        fd.append('tipo',tipo);
        fd.append('pagina',pagina);
        fd.append('agrupar',agrupar);
        fd.append('campo_orden',campo_orden);
        fd.append('orden',orden);

        /* Realizar petición, el método debe ser post y en los encabezados debe ir
        un atributo llamado authorization con el valor bearer seguido del token */
        fetch(url,{
        method:'post',
        headers:{
            Authorization:`bearer ${token}`
        },
        body:fd
        }).then(respuesta=>{
        
        /* Como la respuesta se recibe en formato json se realiza la conversión */
        respuesta.json()
        .then(json=>{

            /* Los accesos vienen en un arreglo. */
            console.log(json);

        });
        }).catch(error=>console.log(error));

    }
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">

    /* Función para obtener las entradas en un rago de fechas */
    function obtenerEntradas($token,$plaza_id,$inicio,$fin,$tipo,$pagina,$agrupar,$campo_orden,$orden){

        /* Url de consulta */
        $url = 'https://app.pogendata.com/api/data/datos';

        /* Se crea un arreglo con los parámetros a enviar */
        $arregloDatos = array(
            'plaza_id'=>$plaza_id,
            'inicio'=>$inicio,
            'fin'=>$fin,
            'tipo'=>$tipo,
            'pagina'=>$pagina,
            'agrupar'=>$agrupar,
            'campo_orden'=>$campo_orden,
            'orden'=>$orden
        );

        /* Se convierte el arreglo datos en objeto de petición */
        $datos = http_build_query($arregloDatos);

        /* Arreglo con opciones de la conexión */
        $opciones = array(
            'http'=>array(
                'method'=>'POST',
                'header'=>array(
                    'Authorization: Bearer ' . $token,
                    'Content-type: application/x-www-form-urlencoded'
                ),
                'content'=>$datos
            )
        );

        /* Crear contexto para petición */
        $contexto = stream_context_create($opciones);

        /* Realizar consulta */
        $respuesta = file_get_contents($url,false,$contexto);

        /* Convertir respuesta en arreglo */
        $json = json_decode($respuesta);

        /* Retornar datos */
        return $json;

    }

                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class="vb">

    /* Clase modelo para la respuesta entradas */
    public class RespuestaEntradas
    {
        public int Pagina { get; set; }

        public int Registros { get; set; }

        public List&lt;ClaseEntradas&gt; Datos { get; set; }
    }

    /* Clase modelo para las entradas */
    public class ClaseEntradas
    {
        public String Entradas { get; set; }

        public int Plaza_id { get; set; }

        public int Acceso_id { get; set; }

        public String Fecha { get; set; }
    }

    /* Método para obtener las entradas */
    public static async Task&lt;RespuestaEntradas&gt; Entradas(String token, int plaza_id, String inicio, String fin, String tipo, int pagina, bool agrupar, String campo_orden, String orden)
    {

        /* Dirección de la consulta */
        String direccion = "https://app.pogendata.com/api/data/datos";

        /* Crear cliente http */
        using(var clienteHttp = new HttpClient())
        {

            /* Agregar token en la cabecera */
            clienteHttp.DefaultRequestHeaders.Add("Authorization", $"Bearer {token}");

            /* Establecer los parámetros de la petición */
            var objetoParametros = HttpUtility.ParseQueryString(String.Empty);

            /* Agregar los parámetros a la cadena */
            objetoParametros["plaza_id"] = $"{plaza_id}";
            objetoParametros["inicio"] = inicio;
            objetoParametros["fin"] = fin;
            objetoParametros["tipo"] = tipo;
            objetoParametros["pagina"] = $"{pagina}";
            objetoParametros["agrupar"] = $"{agrupar}";
            objetoParametros["campo_orden"] = campo_orden;
            objetoParametros["orden"] = orden;

            /* Convertir el objeto en cadena */
            var cadenaParametros = objetoParametros.ToString();

            /* Agregar a la dirección la lista de parámetros */
            direccion += "?" + cadenaParametros;

            /* Realizar petición */
            var respuesta = await clienteHttp.PostAsync(direccion,null);

            /* Convertir la respuesta en una cadena */
            var informacion = await respuesta.Content.ReadAsStringAsync();

            /* Convertir en ojeto json la respuesta */
            var json = JsonConvert.DeserializeObject&lt;RespuestaEntradas&gt;(informacion);

            /* Devolver objeto json */
            return json;

        }
    }
        
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">

    # Importar librerías adicionales
    import requests

    # Método para obtener las entradas
    def obtenerEntradas(token, plaza_id, inicio, fin, tipo, pagina, agrupar, campo_orden, orden):

        # Definir url de la petición
        url = 'https://app.pogendata.com/api/data/datos'

        # Establecer encabezados de la petición
        encabezados = {
            'Authorization' : 'Bearer ' + token
        }

        # Establecer parámetros de la petición
        parametros = {
            'plaza_id' : plaza_id,
            'inicio' : inicio,
            'fin' : fin,
            'tipo' : tipo,
            'pagina' : pagina,
            'agrupar' : agrupar,
            'campo_orden' : campo_orden,
            'orden' : orden,
        }

        # Realizar consulta y asignar respuesta
        respuesta = requests.post(url, params=parametros, headers=encabezados)

        # Convertir respuesta en json
        json = respuesta.json()

        # Retornar entradas
        return json['datos']

                        </code>
                    </pre>                    
                </div>
            </div>
            
            <div class="titulo1">
                Objetivos
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parámetros:
                </div>                
                <ul>
                    <li>
                        <strong>Token</strong>, se debe agregar en la cabecera de la petición
                        como Authorization tipo bearer
                    </li>                    
                    <li>
                       <strong>plaza_id</strong>: el id de la plaza a consultar, si se omite 
                        se obtienen los objetivos de todas las plazas, el tipo
                        de dato es entero
                    </li>
                    <li>
                        <strong>inicio</strong>: fecha del inicio del periodo que se
                        quiere consultar, formato 'YYYY-MM-DD'
                    </li>
                    <li>
                        <strong>fin</strong>: fecha del fin del periodo que se
                        quiere consultar, formato 'YYYY-MM-DD'
                    </li>
                </ul>
                <div class="categoria">
                    Detalles de la petición:
                </div>                
                <ul>
                    <li> <strong>url</strong>: https://app.pogendata.com/api/data/objetivos</li>
                    <li> <strong>método</strong>: get</li>
                </ul>
                <div class="categoria">
                    Respuesta:
                </div>                
                <ul>
                    <li>Objeto tipo json, contiene los datos de los objetivos 
                        en el atributo "data".
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="js">
    /* Función para obtener los objetivos */
    async function obtenerObjetivos(token,plaza_id,inicio,fin){

        /* Url para obtener los objetivos */
        let url = 'https://app.pogendata.com/api/data/objetivos';

        /* El método get permite la concatenación de parámetros en la url de la 
        petición, por lo que se realiza manualmente */
        url += `?plaza_id=${plaza_id}&inicio=${inicio}&fin=${fin}`;

        /* Realizar petición, el método debe ser post y en los encabezados debe ir
        un atributo llamado authorization con el valor bearer seguido del token */
        fetch(url,{
        method:'get',
        headers:{
            Authorization:`bearer ${token}`,
            'Content-Type':'application/json',
        },
        }).then(respuesta=>{
        
        /* Como la respuesta se recibe en formato json se realiza la conversión */
        respuesta.json()
        .then(json=>{

            /* Los objetivos vienen en un arreglo. */
            console.log(json);

        });
        }).catch(error=>console.log(error));

    }
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">

    /* Función para obtener los objetivos de una plaza en un rango de fechas */
    function obtenerObjetivos($token,$plaza_id,$inicio,$fin){

        /* Url de consulta */
        $url = 'https://app.pogendata.com/api/data/objetivos';

        /* Se crea un arreglo con los parámetros a enviar */
        $arregloDatos = array(
            'plaza_id'=>$plaza_id,
            'inicio'=>$inicio,
            'fin'=>$fin
        );

        /* Se convierte el arreglo datos en objeto de petición */
        $datos = http_build_query($arregloDatos);

        /* Arreglo con opciones de la conexión */
        $opciones = array(
            'http'=>array(
                'method'=>'GET',
                'header'=>array(
                    'Authorization: Bearer ' . $token,
                    'Content-type: application/x-www-form-urlencoded'
                ),
            )
        );

        /* Crear contexto para conexión */
        $contexto = stream_context_create($opciones);

        /* Realizar consulta, se concatena la url con el objeto datos */
        $respuesta = file_get_contents($url."?".$datos,false,$contexto);

        /* Convertir respuesta en arreglo */
        $json = json_decode($respuesta);

        /* Retornar datos */
        return $json;

    }

                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class="vb">
/* Clase modelo FechasObjetivo */
public class FechasObjetivo
{
    public String Inicio { get; set; }
    public String Fin { get; set; }
}

/* Clase modelo ClaseObjetivos */
public class ClaseObjetivos
{
    public int Total { get; set; }

    public int Plaza_id { get; set; }
}

/* Método para obtener los objetivos */
public static async Task&lt;RespuestaObjetivo&gt; Objetivos(String token, int plaza_id, String inicio, String fin)
{
    /* Dirección para la petición */
    var direccion = "https://app.pogendata.com/api/data/objetivos";

    /* Crear objeto para los parámetros */
    var objetoParametros = HttpUtility.ParseQueryString(String.Empty);

    /* Agregar parámetros al objeto */
    //objetoParametros["plaza_id"] = $"{plaza_id}";
    objetoParametros["inicio"] = inicio;
    objetoParametros["fin"] = fin;

    /* Cadena para los parámetros */
    var cadenaParametros = objetoParametros.ToString();

    /* Agregar parámetros a la url */
    direccion += "?" + cadenaParametros;

    /* Crear el objeto httpClient */
    using (var clienteHttp = new HttpClient())
    {

        /* Agregar el token a la cabecera de la petición */
        clienteHttp.DefaultRequestHeaders.Add("Authorization", $"Bearer {token}");

        /* Realiar consulta */
        var respuesta = await clienteHttp.GetStringAsync(direccion);

        var json = JsonConvert.DeserializeObject&lt;RespuestaObjetivo&gt;(respuesta);

        /* Retornar json con la información */
        return json;

    }
}        
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">

# Agregar librerias adicionales
import requests

# Método para obtener el objetivo
def obtenerObjetivo(token,plaza_id,inicio,fin):

# Definir url de la petición
url = 'https://app.pogendata.com/api/data/objetivos'

# Definir encabezado de la petición
encabezado = {
    'Authorization' : 'Bearer ' + token
}

# Parámetros
parametros = {
    'plaza_id' : plaza_id,
    'inicio' : inicio,
    'fin' : fin
}

# Realizar petició y asignar resultado
respuesta = requests.get(url, headers=encabezado, params=parametros)

# Convertir respuesta en json
json = respuesta.json()

# Retornar el ojetivo
return json['objetivo']

                        </code>
                    </pre>                    
                </div>
            </div>

            <footer>
                ©2021 Pogen, sistemas@pogen.com
            </footer>

    </div>

</template>
<!-- Código vue -->
<script>

    export default{

        data(){
            return{
                pa:0
            }
        },

        methods:{            
            animarPaneles(indice){
                let clases = ['c_js','c_php','c_net','c_py'];
                this.pa = indice;
                let contador = 0;
                clases.forEach(cU=>{
                    let pa = document.getElementsByClassName(cU);
                    for(let c = 0; c < pa.length; c++){
                        pa[c].style.height = contador === indice ? '50vh' : '0vh';
                    }
                    contador++;
                })
            }
        }

    }

</script>


<!-- Esilo propio -->
<style scoped>
    .contieneTabs{
        display: flex;
    }
    .tab{
        width:10em;
        text-align: center;
        background-color: #ccc;
        padding:.3em 0em;
        border: solid 1px #888;
        cursor: pointer;
    }
    .pizarra{
        height: 50vh;
        margin-bottom: 3em;
    }
    .c_js, .c_php, .c_net, .c_py{
        top: 0em;
        left: 0em;
        height: 0em;
        width: 100%;
        overflow-y: scroll;
        color: white;
        transition:height .3s linear;
    }
    .c_js{
        height: 50vh;
    }
    .seleccionado{
        background-color: #555;
        color: white;
    }
    .titulo1 {
    margin-top: 1em;
    font-size: 1.2em;
    color: #5a5a5a;
    padding-left: 1em;
    font-weight: 500;
    }
    .parrafo {
    text-align: justify;
    text-indent: 1em;
    padding: 0.7em;
    font-size: 0.9em;
    color: #5a5a5a;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    }
    .detalles {
    margin-top: 1.5em;
    padding-left: 1.5em;
    color: #5a5a5a;
    font-size: 0.9em;
    }
    .categoria {
    font-size: 1em;
    font-weight: 400;
    }

    .mt2em {
    margin-top: 2em;
    }
    footer {
    margin-top: 5em;
    text-align: center;
    background-color: #363636;
    border-radius: 0 0 0.5em 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: white;
    font-weight: 500;
    font-size: 0.8em;
    }                    
</style>